import { Email, ProjectUser } from "../../../../models/ProjectUser";
import { Box, Chip, MenuItem, TableCell, TableRow } from "@mui/material";
import { HankoMenuItem, MoreMenu } from "../../../../components/User/MoreMenu";
import React, { useContext, useState } from "react";
import { UserAttributesTable } from "../../../../components/User/AttributesTable";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";
import { useRevalidator } from "react-router-dom";
import { ApiClient } from "../../../../client/ApiClient";
import { SnackbarContext } from "../../../../components/Snackbar/SnackbarProvider";
import { Organization } from "../../../../models/Organization";
import { Project } from "../../../../models/Project";
import { CreateEmailAddressModal } from "./CreateEmailAddressModal";

export const EmailTable = (props: { user: ProjectUser, organization: Organization, project: Project }) => {
  const { organization, project, user } = props
  const [emailShouldBeDeleted, setEmailShouldBeDeleted] = useState<Email | undefined>()
  const revalidator = useRevalidator()
  const [createEmailAddressOpen, setCreateEmailAddressOpen] = useState(false)
  const { error, success } = useContext(SnackbarContext)

  const deleteEmail = async () => {
    try {
      await ApiClient.deleteProjectUserEmailAddress(organization.id, project.id, user.id, emailShouldBeDeleted?.id ?? "")
      revalidator.revalidate()
      setEmailShouldBeDeleted(undefined)
      success("Email address deleted")
    } catch (e) {
      error("failed to delete email address")
    }
  }

  const setAsPrimary = async (email: Email) => {
    try {
      await ApiClient.setProjectUserEmailAsPrimary(organization.id, project.id, user.id, email.id)
      revalidator.revalidate()
      success("New primary email address set")
    } catch (e) {
      error("failed to set new primary email address")
    }
  }

  return <>
    <UserAttributesTable label="Email" head={undefined}
                         rows={user.emails?.map((value) => <EmailRow key={value.id} email={value}
                                                                     deleteEmail={setEmailShouldBeDeleted}
                                                                     setAsPrimary={setAsPrimary} />)}
                         action={{
                           label: "Add new email address", action: () => {
                             setCreateEmailAddressOpen(true)
                           }
                         }}
                         emptyContent="No email set." />
    <ConfirmationModal open={emailShouldBeDeleted !== undefined} onClose={() => setEmailShouldBeDeleted(undefined)}
                       onConfirm={() => {
                         deleteEmail()
                       }} title="Delete email"
                       description={`Are you sure you want to delete the email address '${emailShouldBeDeleted?.address}'?`}
                       buttonText="Delete email address" />
    <CreateEmailAddressModal organizationId={organization.id} projectId={project.id} userId={user.id}
                             open={createEmailAddressOpen} onClose={() => setCreateEmailAddressOpen(false)} />
  </>
}

const EmailRow = (props: {
  email: Email,
  deleteEmail: React.Dispatch<React.SetStateAction<Email | undefined>>,
  setAsPrimary: (email: Email) => void
}) => {
  const { email, deleteEmail, setAsPrimary } = props

  let menuItems: HankoMenuItem[] = []
  let verified = <></>
  let primary = <></>
  if (email.is_verified) {
    verified = <Chip variant="outlined" label="verified" color="success" size="small" />
  }

  if (email.is_primary) {
    primary = <Chip variant="outlined" label="primary" color="primary" size="small" />
  }

  if (!email.is_primary) {
    menuItems.push({
      label: "Set as primary",
      dangerous: false,
      onClick: () => {
        setAsPrimary(email)
      }
    })
  }

  menuItems.push({
    label: "Delete",
    dangerous: true,
    onClick: () => {
      deleteEmail(email)
    }
  })

  return <TableRow sx={{ height: '53px' }}>
    <TableCell>{email.address}</TableCell>
    <TableCell sx={{ width: 0 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
        {verified}
        {primary}
      </Box>
    </TableCell>
    <TableCell sx={{ width: 0 }}>
      {!email.is_primary ?
        <MoreMenu items={menuItems}>
          {!email.is_primary ? <MenuItem onClick={() =>
            setAsPrimary(email)
          }>Set as primary</MenuItem> : null}
          <MenuItem sx={{ color: 'red' }}
                    onClick={() => deleteEmail(email)}>Delete</MenuItem> {/* TODO: use error color from theme*/}
        </MoreMenu> : null}
    </TableCell>
  </TableRow>
}
