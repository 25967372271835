import { LoadingButton } from "@mui/lab";
import { Box, Button, SxProps, Theme, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

interface Props {
  onClick?: () => void
  onCancel: () => void
  loading: boolean
  disabled: boolean
  submitButtonText?: string
  resetButtonText?: string
  sx?: SxProps<Theme>
}

export const FormButtons = (props: Props) => {
  const {onClick, onCancel, loading, disabled, submitButtonText, resetButtonText, sx} = props
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))

  return <Box sx={{ display: 'flex', flexDirection: isXs ? 'column' : 'row-reverse', gap: 2, ...sx}}>
    <LoadingButton disabled={disabled} loading={loading} type="submit" variant="contained"
                   sx={{width: isXs ? '100%' : '160px'}} onClick={onClick}>{submitButtonText ?? "Save"}</LoadingButton>
    <Button disabled={loading || disabled} type="reset" sx={{width: isXs ? '100%' : '80px'}}
            onClick={onCancel}>{resetButtonText ?? "Cancel"}</Button>
  </Box>
}