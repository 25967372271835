import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";

interface Props {
  children?: React.ReactNode
  open: boolean
  onClose: () => void
  fullScreen?: boolean
  closeable: boolean
}

export const HankoDialog = (props: Props) => {
  const theme = useTheme()
  const matchesSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const contentPadding = props.closeable ? "0 60px 56px 60px" : "56px 60px"

  return <Dialog open={props.open}
                 fullScreen={props.fullScreen}
                 PaperProps={{
                   elevation: 0,
                   sx: {
                     borderRadius: props.fullScreen ? "0" : "16px"
                   }
                 }}>
    {props.closeable ? <DialogTitle display="flex" flexDirection="row-reverse"
                                    sx={{ padding: { xs: "12px 12px 0px 24px", md: "16px 24px 0px 24px" } }}>
      <IconButton onClick={props.onClose}>
        <CloseOutlined />
      </IconButton>
    </DialogTitle> : <></>}
    <DialogContent sx={{ display: "flex", padding: matchesSmDown ? "" : contentPadding }}>
      {props.children}
    </DialogContent>
  </Dialog>
}

HankoDialog.defaultProps = {
  closeable: true
}