import { Project } from "../../../../models/Project";
import { ProjectUser, WebAuthnCredential } from "../../../../models/ProjectUser";
import { Organization } from "../../../../models/Organization";
import { TableCell, TableRow } from "@mui/material";
import React, { useContext, useState } from "react";
import { useRevalidator } from "react-router-dom";
import { SnackbarContext } from "../../../../components/Snackbar/SnackbarProvider";
import { ApiClient } from "../../../../client/ApiClient";
import { MoreMenu } from "../../../../components/User/MoreMenu";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";
import { UserAttributesTable } from "../../../../components/User/AttributesTable";

const dateFormat = new Intl.DateTimeFormat('default', {
  dateStyle: "medium",
  timeStyle: "medium",
  timeZone: 'UTC'
})

export const MFATable = (props: { organization: Organization, project: Project, user: ProjectUser }) => {
  const { user } = props

  let rows = undefined
  const securityKeys = user.webauthn_credentials?.filter((item) => item.mfa_only)
  if ((user.otp !== undefined && user.otp !== null) || (securityKeys !== undefined && securityKeys.length > 0)) {
    rows = <>
      <TOTPRow {...props} />
      {securityKeys?.map((item) =>
        <SecurityKeyRow key={item.id} {...props} securityKey={item} />)}
    </>
  }

  return <UserAttributesTable head={<MFATableHead />} emptyContent="No 2FA methods set" label="2FA"
                              rows={rows} />
}

const MFATableHead = () => {
  return <>
    <TableCell>Type</TableCell>
    <TableCell>Name</TableCell>
    <TableCell>Created at (UTC)</TableCell>
    <TableCell></TableCell>
  </>
}

const TOTPRow = (props: {
  organization: Organization,
  project: Project,
  user: ProjectUser
}) => {
  const { organization, project, user } = props

  const [open, setOpen] = useState(false)
  const revalidator = useRevalidator()
  const { success, error } = useContext(SnackbarContext)

  const deleteOTPSecret = async () => {
    try {
      await ApiClient.deleteProjectUserOtpSecret(organization.id, project.id, user.id)
      revalidator.revalidate()
      success("TOTP deleted")
      setOpen(false)
    } catch (e) {
      error("Failed to delete TOTP")
    }
  }

  if (user.otp === undefined || user.otp === null) {
    return null
  }

  return <>
    <TableRow>
      <TableCell>TOTP</TableCell>
      <TableCell></TableCell>
      <TableCell>{dateFormat.format(new Date(user.otp!.created_at))}</TableCell>
      <TableCell sx={{ width: 0 }}>
        <MoreMenu items={[
          {
            label: "Delete",
            dangerous: true,
            onClick: () => {
              setOpen(true)
            }
          }
        ]} />
      </TableCell>
    </TableRow>
    <ConfirmationModal open={open} onClose={() => setOpen(false)}
                       onConfirm={() => {
                         deleteOTPSecret()
                       }} title="Delete OTP"
                       description="Are you sure you want to delete the OTP secret?"
                       buttonText="Delete OTP" />
  </>
}

const SecurityKeyRow = (props: {
  organization: Organization,
  project: Project,
  user: ProjectUser,
  securityKey: WebAuthnCredential
}) => {
  const { organization, project, user, securityKey } = props
  const [open, setOpen] = useState(false)
  const revalidator = useRevalidator()
  const { success, error } = useContext(SnackbarContext)

  const uiDisplayName = (credential: WebAuthnCredential) => {
    if (credential.name) {
      return credential.name;
    }
    const alphanumeric = credential.public_key.replace(/[\W_]/g, "");
    return `SecurityKey-${alphanumeric.substring(
      alphanumeric.length - 7,
      alphanumeric.length
    )}`;
  }

  const deleteSecurityKey = async () => {
    try {
      await ApiClient.deleteProjectUserWebAuthnCredential(organization.id, project.id, user.id, securityKey.id)
      revalidator.revalidate()
      success("Security key deleted")
      setOpen(false)
    } catch (e) {
      error("Failed to delete security key")
    }
  }

  return <>
    <TableRow>
      <TableCell>Security Key</TableCell>
      <TableCell>{uiDisplayName(securityKey)}</TableCell>
      <TableCell>{dateFormat.format(new Date(securityKey.created_at))}</TableCell>
      <TableCell sx={{ width: 0 }}>
        <MoreMenu items={[
          {
            label: "Delete",
            dangerous: true,
            onClick: () => {
              setOpen(true)
            }
          }
        ]} />
      </TableCell>
    </TableRow>
    <ConfirmationModal open={open} onClose={() => setOpen(false)}
                       onConfirm={() => {
                         deleteSecurityKey()
                       }} title="Delete Security Key"
                       description="Are you sure you want to delete the security key?"
                       buttonText="Delete security key" />
  </>
}
