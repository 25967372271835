import { ProjectUser, WebAuthnCredential } from "../../../../models/ProjectUser";
import { UserAttributesTable } from "../../../../components/User/AttributesTable";
import React, { useContext, useState } from "react";
import { Chip, TableCell, TableRow } from "@mui/material";
import { MoreMenu } from "../../../../components/User/MoreMenu";
import { Organization } from "../../../../models/Organization";
import { Project } from "../../../../models/Project";
import { SnackbarContext } from "../../../../components/Snackbar/SnackbarProvider";
import { ApiClient } from "../../../../client/ApiClient";
import { useRevalidator } from "react-router-dom";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";

export const PasskeyTable = (props: { organization: Organization, project: Project, user: ProjectUser }) => {
  const { user } = props

  const passkeys = user.webauthn_credentials?.filter((item) => !item.mfa_only)
  let rows = undefined
  if ((passkeys?.length ?? 0) > 0) {
    rows = passkeys?.map(value => <PasskeyRow key={value.id} passkey={value} {...props} />)
  }

  return <UserAttributesTable label="Passkeys" head={<PasskeyTableHead />}
                              rows={rows} emptyContent="No passkeys set." />
}

const PasskeyTableHead = () => {
  return <>
    <TableCell sx={{ height: '53px' }}>Name</TableCell>
    <TableCell>Created at (UTC)</TableCell>
    <TableCell>Backup state</TableCell>
    <TableCell></TableCell>
  </>
}

const PasskeyRow = (props: {
  organization: Organization,
  project: Project,
  user: ProjectUser,
  passkey: WebAuthnCredential
}) => {
  const { organization, project, user, passkey } = props
  const [open, setOpen] = useState(false)
  const revalidator = useRevalidator()
  const { success, error } = useContext(SnackbarContext)

  const dateFormat = new Intl.DateTimeFormat('default', {
    dateStyle: "medium",
    timeStyle: "medium",
    timeZone: 'UTC'
  })

  const uiDisplayName = (credential: WebAuthnCredential) => {
    if (credential.name) {
      return credential.name;
    }
    const alphanumeric = credential.public_key.replace(/[\W_]/g, "");
    return `Passkey-${alphanumeric.substring(
      alphanumeric.length - 7,
      alphanumeric.length
    )}`;
  }

  const getBackupState = (credential: WebAuthnCredential) => {
    if (credential.backup_state) {
      return <Chip variant="outlined" label="Backed up" color="success" size="small" />
    } else if (credential.backup_eligible) {
      return <Chip variant="outlined" label="Eligible" color="primary" size="small" />
    } else {
      return <Chip variant="outlined" label="Device-bound" size="small" />
    }
  }

  const deletePasskey = async () => {
    try {
      await ApiClient.deleteProjectUserWebAuthnCredential(organization.id, project.id, user.id, passkey.id)
      revalidator.revalidate()
      success("Passkey deleted")
      setOpen(false)
    } catch (e) {
      error("Failed to delete passkey")
    }
  }

  return <>
    <TableRow>
      <TableCell>{uiDisplayName(passkey)}</TableCell>
      <TableCell>{dateFormat.format(new Date(passkey.created_at))}</TableCell>
      <TableCell>{getBackupState(passkey)}</TableCell>
      <TableCell sx={{ width: 0 }}>
        <MoreMenu items={[
          {
            label: "Delete",
            dangerous: true,
            onClick: () => {
              setOpen(true)
            }
          }
        ]} />
      </TableCell>
    </TableRow>
    <ConfirmationModal open={open} onClose={() => setOpen(false)}
                       onConfirm={() => {
                         deletePasskey()
                       }} title="Delete passkey"
                       description="Are you sure you want to delete the passkey?"
                       buttonText="Delete passkey" />
  </>
}
