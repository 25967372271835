import React, { useContext, useState } from "react";
import { SnackbarContext } from "../../../../components/Snackbar/SnackbarProvider";
import { ApiClient } from "../../../../client/ApiClient";
import { HankoDialog } from "../../../../components/HankoDialog";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, TextField, Typography } from "@mui/material";
import { FormButtons } from "../../../../components/form/FormButtons";
import { useRevalidator } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

interface Props {
  organizationId: string
  projectId: string
  userId: string
  open: boolean
  onClose: () => void
}

export const CreateEmailAddressModal = (props: Props) => {
  const { organizationId, projectId, userId, open, onClose } = props
  const { error, success } = useContext(SnackbarContext)
  const [isLoading, setIsLoading] = useState(false)
  const revalidator = useRevalidator()

  const onSubmit = async (e: React.FormEvent) => {
    setIsLoading(true)
    e.preventDefault()
    const target = e.target as typeof e.target & {
      email_address: { value: string }
      is_verified: { checked: boolean }
    }

    try {
      await ApiClient.createProjectUserEmailAddress(organizationId, projectId, userId, {
        address: target.email_address.value,
        is_verified: target.is_verified.checked,
      })
      revalidator.revalidate()
      success("Email address added")
      onClose()
    } catch (err) {
      error("failed to add email address")
    }

    setIsLoading(false)
  }

  return <HankoDialog onClose={onClose} open={open}>
    <Box sx={{ display: "flex", flexDirection: 'column', width: '320px', maxWidth: '320px', margin: 'auto' }}>
      <Typography variant="h4">Add email address</Typography>
      <Typography sx={{ mt: 2.5, mb: 2.5 }}>Add a new email address to the user.</Typography>
      <form onSubmit={onSubmit}>
        <FormControl fullWidth focused>
          <TextField type="email" required name="email_address" placeholder="example@example.com"
                     autoFocus label="Email Address" />
          <FormGroup sx={{ mt: 1 }}>
            <FormControlLabel control={<Checkbox />} label="Set email address as verified" name="is_verified" />
          </FormGroup>
          <LoadingButton loading={isLoading} variant="contained" type="submit" fullWidth sx={{
            mt: 2,
            mb: 2
          }}>Create email</LoadingButton>
          <Button variant="text" fullWidth onClick={onClose}>Cancel</Button>
        </FormControl>
      </form>
    </Box>
  </HankoDialog>
}