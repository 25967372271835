import { Password, ProjectUser } from "../../../../models/ProjectUser";
import { UserAttributesTable } from "../../../../components/User/AttributesTable";
import React, { useContext, useState } from "react";
import { TableCell, TableRow } from "@mui/material";
import { Organization } from "../../../../models/Organization";
import { Project } from "../../../../models/Project";
import { PasswordModal } from "./PasswordModal";
import { HankoMenuItem, MoreMenu } from "../../../../components/User/MoreMenu";
import { ApiClient } from "../../../../client/ApiClient";
import { useRevalidator } from "react-router-dom";
import { SnackbarContext } from "../../../../components/Snackbar/SnackbarProvider";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";


/**
 * PasswordTable shows the users password state. Although it is a table a user will only have one password.
 * But with a table it is easier to achieve the desired UI.
 * @param props
 * @constructor
 */
export const PasswordTable = (props: { organization: Organization, project: Project, user: ProjectUser }) => {
  const { organization, project, user } = props
  const [open, setOpen] = useState(false)

  return <>
    <UserAttributesTable label="Password" head={undefined}
                         rows={user.password ? <PasswordRow {...props} password={user.password} /> : undefined}
                         action={user.password === undefined ? {
                           label: "Set password", action: () => {
                             setOpen(true)
                           }
                         } : undefined}
                         emptyContent="No password set." />
    <PasswordModal type="create" organizationId={organization.id} projectId={project.id} userId={user.id} open={open}
                   onClose={() => setOpen(false)} />
  </>
}

const PasswordRow = (props: {
  organization: Organization,
  project: Project,
  user: ProjectUser,
  password: Password
}) => {
  const { organization, project, user, password } = props
  const revalidator = useRevalidator()
  const { success, error } = useContext(SnackbarContext)
  const dateFormat = new Intl.DateTimeFormat('default', {
    dateStyle: "medium",
    timeStyle: "medium",
    timeZone: 'UTC'
  })
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [updateModalOpen, setUpdateModalOpen] = useState(false)

  const deletePassword = async () => {
    try {
      await ApiClient.deleteProjectUserPassword(organization.id, project.id, user.id)
      revalidator.revalidate()
      setDeleteModalOpen(false)
      success("Password deleted")
    } catch (e) {
      error("Failed to delete password")
    }
  }

  const menuItems: HankoMenuItem[] = [
    {
      label: "Update",
      dangerous: false,
      onClick: () => {
        setUpdateModalOpen(true)
      }
    },
    {
      label: "Delete",
      dangerous: true,
      onClick: () => {
        setDeleteModalOpen(true)
      }
    }
  ]

  return <TableRow sx={{ height: '53px' }}>
    <TableCell>Last changed: {dateFormat.format(new Date(password.updated_at))}</TableCell>
    <TableCell sx={{ width: 0 }}>
      <MoreMenu items={menuItems} />
    </TableCell>
    <PasswordModal type="update" organizationId={organization.id} projectId={project.id} userId={user.id}
                   open={updateModalOpen} onClose={() => setUpdateModalOpen(false)} />
    <ConfirmationModal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)} onConfirm={deletePassword}
                       title="Delete password" description="Are you sure you want to delete the password?"
                       buttonText="Delete password" />
  </TableRow>
}
