// @ts-nocheck
import { useEffect } from "react";
import { ApiClient } from "../client/ApiClient";

export const ChatwootWidget = (props: { isLoggedIn: boolean }) => {

  useEffect(() => {
    if (props.isLoggedIn) {
      // Add Chatwoot Settings
      window.chatwootSettings = {
        hideMessageBubble: false,
        position: 'right',
        locale: 'en',
        type: 'standard',
      };

      if (!document.getElementById("chatwoot-script")) {
        (function (d, t) {
          var BASE_URL = "https://app.chatwoot.com";
          var g = d.createElement(t), s = d.getElementsByTagName(t)[0];
          g.src = BASE_URL + "/packs/js/sdk.js";
          g.defer = true;
          g.async = true;
          s.parentNode.insertBefore(g, s);
          g.onload = function () {
            window.chatwootSDK.run({
              websiteToken: window.REACT_APP_CHATWOOT_WEBSITE_TOKEN,
              baseUrl: BASE_URL
            })
          }
          g.id = "chatwoot-script"
        })(document, "script");

        window.addEventListener("chatwoot:ready", function () {
          ApiClient.getChatwootUserInfo().then((u) => {
            window.$chatwoot.setUser(u.user_id, {
              email: u.email,
              name: u.email,
              identifier_hash: u.identifier_hash
            })
          })
        });
      }

      if (window.$chatwoot?.hasLoaded) {
        window.$chatwoot.toggleBubbleVisibility("show")
        ApiClient.getChatwootUserInfo().then((u) => {
          window.$chatwoot.setUser(u.user_id, {
            email: u.email,
            name: u.email,
            identifier_hash: u.identifier_hash
          })
        })
      }
    }
    return () => {
      window.$chatwoot?.toggleBubbleVisibility("hide")
      window.$chatwoot.reset();
    }
  }, [props.isLoggedIn])

  return null
}