import { UserAttributesTable } from "../../../../components/User/AttributesTable";
import React, { useContext, useState } from "react";
import { ProjectUser, Session } from "../../../../models/ProjectUser";
import { CircularProgress, TableCell, TableRow } from "@mui/material";
import { Await, useLoaderData, useRevalidator } from "react-router-dom";
import { MoreMenu } from "../../../../components/User/MoreMenu";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";
import { ApiClient } from "../../../../client/ApiClient";
import { Project } from "../../../../models/Project";
import { Organization } from "../../../../models/Organization";
import { SnackbarContext } from "../../../../components/Snackbar/SnackbarProvider";

const dateFormatter = new Intl.DateTimeFormat("default", {
  dateStyle: "medium",
  timeStyle: "medium",
  timeZone: 'UTC'
})

export const SessionTable = (props: { organization: Organization, project: Project, user: ProjectUser }) => {
  const data = useLoaderData() as any

  return <React.Suspense fallback={<CircularProgress />}>
    <Await resolve={data.sessions} errorElement={<></>}>
      {(sessions: Awaited<Session[]>) => (
        <UserAttributesTable head={<SessionTableHead />} emptyContent="No active sessions." label="Sessions"
                             rows={sessions.map((item) => {
                               return <Row key={item.id} {...props} session={item} />
                             })} />
      )}
    </Await>
  </React.Suspense>
}

const SessionTableHead = () => {
  return <>
    <TableCell>Created at (UTC)</TableCell>
    <TableCell>Last active (UTC)</TableCell>
    <TableCell>Device</TableCell>
    <TableCell sx={{ height: '53px' }}>Browser</TableCell>
    <TableCell>IP Address</TableCell>
    <TableCell sx={{ width: 0 }}></TableCell>
  </>
}

const Row = (props: { organization: Organization, project: Project, user: ProjectUser, session: Session }) => {
  const { organization, project, user, session } = props
  const [open, setOpen] = useState(false)
  const revalidator = useRevalidator()
  const { error, success } = useContext(SnackbarContext)


  const deleteSession = async () => {
    try {
      await ApiClient.deleteProjectUserSession(organization.id, project.id, user.id, session.id)
      success("Sessions deleted successfully")
      revalidator.revalidate()
      setOpen(false)
    } catch (e) {
      error("Failed to delete session")
    }
  }

  return <>
    <TableRow sx={{ height: '53px' }}>
      <TableCell>{dateFormatter.format(new Date(session.created_at))}</TableCell>
      <TableCell>{dateFormatter.format(new Date(session.last_used))}</TableCell>
      <TableCell>{session.os}</TableCell>
      <TableCell>{session.browser}</TableCell>
      <TableCell>{session.ip_address}</TableCell>
      <TableCell sx={{ width: 0 }}>
        <MoreMenu items={[
          {
            label: "Delete",
            dangerous: true,
            onClick: () => {
              setOpen(true)
            }
          }
        ]} />
      </TableCell>
    </TableRow>
    <ConfirmationModal open={open} onClose={() => setOpen(false)}
                       onConfirm={() => {
                         deleteSession()
                       }} title="Delete session"
                       description={`Are you sure you want to delete the session and logout the user?`}
                       buttonText="Delete session" />
  </>
}
