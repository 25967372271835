import React, { useState } from "react";
import { IconButton, MenuItem } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";

export interface HankoMenuItem {
  label: string
  onClick?: () => void
  dangerous?: boolean
}

export const MoreMenu = (props: { children?: React.ReactNode, items?: HankoMenuItem[] }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return <>
    <IconButton onClick={handleClick}><MoreHorizIcon /></IconButton>
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
      {props.items?.map((value) => {
        return <MenuItem key={value.label} onClick={() => {
          value.onClick?.()
          handleClose()
        }} sx={{color: (value.dangerous ?? false) ? 'red' : 'white'}}>{value.label}</MenuItem>
      })}
    </Menu>
  </>
}