import { IApiClient } from "./ApiClient";
import { User } from "../models/User";
import { Organization, UpdateOrganizationData } from "../models/Organization";
import {
  CreateApiKeyResponse,
  CreateUpdateSamlIdentityProvider,
  CustomDomain,
  Project,
  SamlIdentityProvider,
  UpdateProjectData
} from "../models/Project";
import { CheckoutSession, CustomerPortalSession, Subscription } from "../models/Subscription";
import {
  CreateEmailAddress,
  CreateOrUpdatePassword,
  Email,
  ProjectUser,
  ProjectUsersWithTotalCount,
  Session
} from "../models/ProjectUser";
import { AuditLog, AuditLogsWithTotalCount } from '../models/AuditLog'
import { wait } from "@testing-library/user-event/dist/utils";
import { MonthlyActiveUser } from "../models/MonthlyActiveUser";
import { DailyActiveUser, MonthlyActiveUserStats } from "../models/DailyActiveUser";
import { ProjectStatus } from "../models/ProjectStatus";
import { InviteUserData, OrganizationUser } from "../models/OrganizationUser";
import { PrivateInvitation, PublicInvitation } from "../models/Invitation";
import { TotalUser } from "../models/TotalUser";
import { DailySignupStats, MonthlySignupStats } from "../models/SignUpStats";
import { UserImportJob } from "../models/UserImport";
import { CreateWebhook, DeleteWebhook, UpdateWebhook, Webhook } from "../models/Webhooks";
import { ProjectType } from "../const/types";
import { CreateCustomDomain } from "../models/CustomDomain";
import { BillingUsage } from "../models/BillingUsage";
import { ChatwootUserInfo } from "../models/ChatwootUserInfo";

export class MockApiClient implements IApiClient {
  getCurrentUser(request: Request): Promise<User> {
    return Promise.resolve({
      id: "2f4dcc30-7e10-4283-be5a-ea2419e18c5a",
      organizations: [organization, organization_2],
      active_organization_id: undefined,
      created_at: "2022-09-28T14:39:53.364407Z",
      updated_at: "2022-09-28T14:39:53.364407Z"
    });
  }

  getOrganization(request: Request, organizationId: string): Promise<Organization> {
    if (organizationId === organization.id) {
      return Promise.resolve(organization);
    } else {
      return Promise.resolve(organization_2)
    }
  }

  getProject(request: Request, organizationId: string, projectId: string): Promise<Project> {
    if (projectId === project.id) {
      return Promise.resolve(project);
    } else {
      return Promise.resolve(project2)
    }
  }

  getProjects(request: Request, organizationId: string): Promise<Project[]> {
    return wait(1000).then(() => {
      return Promise.resolve([project, project2]);
    })
  }

  createOrganization(organizationName: string): Promise<Organization> {
    return Promise.resolve(organization)
  }

  updateOrganization(organizationId: string, data: UpdateOrganizationData): Promise<Organization> {
    return Promise.resolve(organization)
  }

  deleteOrganization(organizationId: string): Promise<any> {
    return Promise.resolve()
  }

  getOrganizationUsers(organizationId: string): Promise<OrganizationUser[]> {
    return wait(1000).then(() => {
      return Promise.resolve([
        {
          id: crypto.randomUUID(),
          email: "example@example.com",
          role: {
            id: 1,
            name: "Owner"
          },
          created_at: "",
          updated_at: ""
        },
        {
          id: crypto.randomUUID(),
          email: "a_very_long_email_to_break_the_layout@bug.com",
          role: {
            id: 2,
            name: "Admin"
          },
          created_at: "",
          updated_at: ""
        }
      ])
    })
  }

  deleteUserFromOrganization(organizationId: string, userId: string): Promise<any> {
    return Promise.resolve()
  }

  inviteUser(organizationId: string, data: InviteUserData): Promise<InviteUserData> {
    return Promise.resolve(data)
  }

  createProject(organizationId: string, projectName: string, projectUrl: string, passwordsEnabled: boolean): Promise<Project> {
    return Promise.resolve(project)
  }

  updateProject(organizationId: string, projectId: string, data: UpdateProjectData): Promise<Project> {
    return Promise.resolve(project)
  }

  deleteProject(organizationId: string, projectId: string): Promise<any> {
    return Promise.resolve()
  }

  getProjectStatus(organizationId: string, projectId: string): Promise<ProjectStatus> {
    return Promise.resolve(projectStatus)
  }

  getStripeCheckoutSession(organizationId: string, queryParams: URLSearchParams): Promise<CheckoutSession> {
    return Promise.resolve({ url: "https://example.com/success" })
  }

  getProjectUsers(organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<ProjectUsersWithTotalCount> {
    return wait(2000).then(() => {
      const list: ProjectUser[] = []
      const perPage = parseInt(queryParams.get("per_page") ?? "0", 10)
      for (let i = 0; i < perPage; i++) {
        list.push({
          id: crypto.randomUUID(),
          emails: [{
            id: crypto.randomUUID(),
            address: "exmaple@example.com",
            is_primary: true,
            is_verified: i % 2 === 0,
            created_at: "2022-10-05T14:48:00.000Z",
            updated_at: "2022-10-05T14:48:00.000Z"
          }],
          username: {
            id: crypto.randomUUID(),
            username: "example_username",
            created_at: "2022-10-05T14:48:00.000Z",
            updated_at: "2022-10-05T14:48:00.000Z"
          },
          webauthn_credentials: [],
          created_at: "2022-10-05T14:48:00.000Z",
          updated_at: "2022-10-05T14:48:00.000Z"
        })
      }
      return Promise.resolve({ user: list, totalCount: list.length })
    })
  }

  getAuditLogs(organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<AuditLogsWithTotalCount> {
    return wait(2000).then(() => {
      const list: AuditLog[] = []
      const perPage = parseInt(queryParams.get("per_page") ?? "0", 10)
      for (let i = 0; i < perPage; i++) {
        list.push({
          id: crypto.randomUUID(),
          type: auditLogTypes[Math.floor(Math.random() * 17)],
          meta_http_request_id: crypto.randomUUID(),
          meta_source_ip: "127.0.0.1",
          meta_user_agent: "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/51.0.2704.103 Safari/537.36",
          actor_user_id: crypto.randomUUID(),
          actor_email: i % 3 === 0 ? undefined : "example@example.com",
          created_at: "2022-10-05T14:48:00.000Z",
          updated_at: "2022-10-05T14:48:00.000Z",
          details: {
            "flow_id": "testing",
            "username": "example",
            "password": true,
            "otp_secret": "sdlfhjsdjk",
            "passkey": "sdhfkjlsd",
            "context": "profile",
            "api": "admin",
          },
        })
      }

      return Promise.resolve({ logs: list, totalCount: list.length })
    })
  }

  getProjectUser(request: Request, organizationId: string, projectId: string, userId: string): Promise<ProjectUser> {
    return Promise.resolve({
      id: userId,
      emails: [
        {
          id: "1",
          address: "exmaple@example.com",
          is_primary: true,
          is_verified: true,
          created_at: "2022-10-05T14:48:00.000Z",
          updated_at: "2022-10-05T14:48:00.000Z"
        },
        {
          id: "2",
          address: "not_verified@example.com",
          is_primary: false,
          is_verified: false,
          created_at: "2022-10-05T14:48:00.000Z",
          updated_at: "2022-10-05T14:48:00.000Z"
        },
        {
          id: "3",
          address: "verified@example.com",
          is_primary: false,
          is_verified: true,
          created_at: "2022-10-05T14:48:00.000Z",
          updated_at: "2022-10-05T14:48:00.000Z"
        },
      ],
      identities: [{
        id: "3",
        email_id: "3",
        provider_id: '123456789',
        provider_name: 'google',
        created_at: "2022-10-05T14:48:00.000Z",
        updated_at: "2022-10-05T14:48:00.000Z"
      }],
      webauthn_credentials: [
        {
          id: '1',
          aaguid: '',
          public_key: '',
          created_at: '2022-10-05T14:48:00.000Z',
          attestation_type: 'none',
          name: 'device bound',
          backup_eligible: false,
          backup_state: false,
          mfa_only: false
        },
        {
          id: '2',
          aaguid: '',
          public_key: '',
          created_at: '2022-10-05T14:48:00.000Z',
          attestation_type: 'none',
          name: 'eligible',
          backup_eligible: true,
          backup_state: false,
          mfa_only: false
        },
        {
          id: '3',
          aaguid: '',
          public_key: '',
          created_at: '2022-10-05T14:48:00.000Z',
          attestation_type: 'none',
          name: 'backed up',
          backup_eligible: true,
          backup_state: true,
          mfa_only: false
        },
        {
          id: '4',
          aaguid: '',
          public_key: '',
          created_at: '2022-10-05T14:48:00.000Z',
          attestation_type: 'none',
          name: 'Security key 1',
          backup_eligible: false,
          backup_state: false,
          mfa_only: true
        },
        {
          id: '5',
          aaguid: '',
          public_key: '',
          created_at: '2022-10-05T14:48:00.000Z',
          attestation_type: 'none',
          name: 'Security Key 2',
          backup_eligible: true,
          backup_state: true,
          mfa_only: true
        }
      ],
      otp: {
        id: "ssadsad",
        created_at: "2022-10-05T14:48:00.000Z"
      },
      created_at: "2022-10-05T14:48:00.000Z",
      updated_at: "2022-10-05T14:48:00.000Z",
      password: {
        id: crypto.randomUUID(),
        created_at: "2022-10-05T14:48:00.000Z",
        updated_at: "2022-10-05T14:48:00.000Z",
      },
      username: {
        id: crypto.randomUUID(),
        username: "roland_weißnicht",
        created_at: "2022-10-05T14:48:00.000Z",
        updated_at: "2022-10-05T14:48:00.000Z",
      }
    })
  }

  deleteProjectUser(organizationId: string, projectId: string, userId: string): Promise<any> {
    return Promise.reject()
  }

  createProjectUser(organizationId: string, projectId: string, email: string, isEmailVerified: boolean): Promise<ProjectUser> {
    return Promise.resolve({
      id: crypto.randomUUID(),
      emails: [{
        id: crypto.randomUUID(),
        address: email,
        is_primary: true,
        is_verified: isEmailVerified,
        created_at: "2022-10-05T14:48:00.000Z",
        updated_at: "2022-10-05T14:48:00.000Z"
      }],
      webauthn_credentials: [],
      created_at: "2022-10-05T14:48:00.000Z",
      updated_at: "2022-10-05T14:48:00.000Z"
    })
  }

  getSubscription(request: Request, organizationId: string): Promise<Subscription | null> {
    if (organizationId === '1990ab0c-d589-4dd5-b321-27b3e26f6851') {
      return Promise.resolve({
        id: "sdfds",
        current_period_start: 1734538409,
        current_period_end: 1737213209,
        is_active: true,
        status: 'active',
        created_at: "",
        updated_at: "",
        type: "standard",
        included_quantities: [
          {
            quantity: 1,
            type: "enterprise_connections",
            context: "project",
          },
          {
            quantity: 10000,
            type: "maus",
            context: "organization",
          }
        ],
        prices: [
          {
            feature: "base_fee",
            currency: "USD",
            amount: 2900
          },
          {
            feature: "mau",
            currency: "USD",
            amount: 1
          },
          {
            feature: "enterprise_connections",
            currency: "USD",
            amount: 4900,
          }
        ]
      })
    } else {
      return Promise.resolve({
        id: "sdfds",
        current_period_start: 1,
        current_period_end: 2,
        is_active: false,
        status: 'canceled',
        created_at: "",
        updated_at: "",
        type: "standard",
        included_quantities: [],
        prices: []
      })
    }
  }

  getBillingUsage(request: Request, organizationId: string): Promise<BillingUsage> {
    if (organizationId === '1990ab0c-d589-4dd5-b321-27b3e26f6851') {
      return Promise.resolve({
        projects: 4,
        mau: {
          total: 12345,
          free: 10000,
          paid: 2345,
        },
        enterprise_connections: {
          total: 5,
          free: 2,
          paid: 3,
        },
      })
    } else {
      return Promise.resolve({
        projects: 1,
        mau: {
          total: 9000,
          free: 9000,
          paid: 0,
        },
        enterprise_connections: {
          total: 0,
          free: 0,
          paid: 0,
        },
      })
    }
  }

  getStripeCustomerPortalSession(organizationId: string, queryParams: URLSearchParams): Promise<CustomerPortalSession> {
    return Promise.resolve({ url: "https://example.com/customer_portal" })
  }

  signOut() {
    return Promise.reject()
  }

  getMonthlyActiveUsers(request: Request, organizationId: string, projectId: string): Promise<MonthlyActiveUser> {
    return wait(1000).then(() => {
      return Promise.resolve({
        id: "",
        count: 0,
        start_time: "",
        end_time: "",
        created_at: "",
        updated_at: ""
      })
    })
  }

  getDailyActiveUsers(projectType: ProjectType, organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<DailyActiveUser[]> {
    return wait(1000).then(() => {
      return Promise.resolve([
        {
          id: "",
          count: 0,
          end_time: "2022-10-22T23:59:99.999999Z",
          start_time: "2022-10-22T00:00:00Z",
          created_at: "",
          updated_at: ""
        },
        {
          id: "",
          count: 10000000,
          end_time: "2022-10-23T23:59:99.999999Z",
          start_time: "2022-10-23T00:00:00Z",
          created_at: "",
          updated_at: ""
        }
      ])
    })
  }

  getMonthlyActiveUsersList(projectType: ProjectType, organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<MonthlyActiveUserStats[]> {
    return Promise.resolve([
      {
        count: 0,
        date: "2022-10-31T00:00:00.000000Z",
      },
      {
        count: 10000000,
        date: "2022-11-01T00:00:00.000000Z",
      }
    ])
  }

  reactivateProject(organizationId: string, projectId: string): Promise<Project> {
    return Promise.resolve(project);
  }

  getInvitation(token: string): Promise<PublicInvitation> {
    return Promise.resolve({
      token: token,
      organization: organization,
    })
  }

  acceptInvitation(token: string): Promise<PublicInvitation> {
    return Promise.resolve({
      token: token,
      organization: organization,
    })
  }

  declineInvitation(token: string): Promise<any> {
    return Promise.resolve()
  }

  listInvitations(organizationId: string): Promise<PrivateInvitation[]> {
    return Promise.resolve([
      {
        id: crypto.randomUUID(),
        token: "token",
        organization: organization,
        email: "example@example.com",
        role: {
          id: 2,
          name: "admin"
        },
        created_at: "2022-09-29T14:39:53.364407Z",
        updated_at: "2022-09-29T14:39:53.364407Z"
      },
      {
        id: crypto.randomUUID(),
        token: "token",
        organization: organization,
        email: "a_long_invitation_email_to_break_the_layout@invites.com",
        role: {
          id: 2,
          name: "admin"
        },
        created_at: "2022-09-29T14:39:53.364407Z",
        updated_at: "2022-09-29T14:39:53.364407Z"
      }
    ])
  }

  revokeInvitation(organizationId: string, invitationId: string): Promise<any> {
    return Promise.resolve()
  }

  getDailySignUpStats(organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<DailySignupStats[]> {
    return wait(1000).then(() => {
      return Promise.resolve([
        {
          id: "1",
          project_id: projectId,
          start_time: "2022-09-25T00:00:00.000000Z",
          end_time: "2022-09-25T23:59:59.999999Z",
          count: 50,
          created_at: "2022-09-29T14:39:53.364407Z",
          updated_at: "2022-09-29T14:39:53.364407Z"
        },
        {
          id: "2",
          project_id: projectId,
          start_time: "2022-09-26T00:00:00.000000Z",
          end_time: "2022-09-26T23:59:59.999999Z",
          count: 5,
          created_at: "2022-09-29T14:39:53.364407Z",
          updated_at: "2022-09-29T14:39:53.364407Z"
        },
        {
          id: "3",
          project_id: projectId,
          start_time: "2022-09-27T00:00:00.000000Z",
          end_time: "2022-09-27T23:59:59.999999Z",
          count: 20,
          created_at: "2022-09-29T14:39:53.364407Z",
          updated_at: "2022-09-29T14:39:53.364407Z"
        },
        {
          id: "4",
          project_id: projectId,
          start_time: "2022-09-28T00:00:00.000000Z",
          end_time: "2022-09-28T23:59:59.999999Z",
          count: 25,
          created_at: "2022-09-29T14:39:53.364407Z",
          updated_at: "2022-09-29T14:39:53.364407Z"
        },
        {
          id: "5",
          project_id: projectId,
          start_time: "2022-09-29T00:00:00.000000Z",
          end_time: "2022-09-29T23:59:59.999999Z",
          count: 30,
          created_at: "2022-09-29T14:39:53.364407Z",
          updated_at: "2022-09-29T14:39:53.364407Z"
        }
      ])
    })
  }

  getMonthlySignUpStats(organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<MonthlySignupStats[]> {
    return wait(1000).then(() => {
      return Promise.resolve([
        {
          date: "2022-11-01T00:00:00.000000Z",
          count: 0
        },
        {
          date: "2022-12-01T00:00:00.000000Z",
          count: 10
        },
        {
          date: "2022-01-01T00:00:00.000000Z",
          count: 200
        },
        {
          date: "2022-02-01T00:00:00.000000Z",
          count: 10
        },
        {
          date: "2022-03-01T00:00:00.000000Z",
          count: 50
        },
        {
          date: "2022-04-01T00:00:00.000000Z",
          count: 100
        }
      ])
    })
  }

  getTotalUserMonthly(organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<TotalUser[]> {
    return wait(1000).then(() => {
      return Promise.resolve([
        {
          id: "hsdkjfhg",
          count: 15,
          record_time: "2022-09-01T14:39:53.364407Z",
          created_at: "2022-09-29T14:39:53.364407Z",
          updated_at: "2022-09-29T14:39:53.364407Z"
        },
        {
          id: "hsdkjfhf",
          count: 45,
          record_time: "2022-10-01T14:39:53.364407Z",
          created_at: "2022-09-29T14:39:53.364407Z",
          updated_at: "2022-09-29T14:39:53.364407Z"
        },
        {
          id: "hsdkjfhs",
          count: 135,
          record_time: "2022-11-01T14:39:53.364407Z",
          created_at: "2022-09-29T14:39:53.364407Z",
          updated_at: "2022-09-29T14:39:53.364407Z"
        }
      ])
    })
  }

  getTotalUserDaily(organizationId: string, projectId: string, queryParams: URLSearchParams): Promise<TotalUser[]> {
    return wait(1000).then(() => {
      return Promise.resolve([
        {
          id: "1",
          count: 0,
          record_time: "2022-10-01T14:39:53.364407Z",
          created_at: "2022-09-29T14:39:53.364407Z",
          updated_at: "2022-09-29T14:39:53.364407Z"
        },
        {
          id: "2",
          count: 0,
          record_time: "2022-10-02T14:39:53.364407Z",
          created_at: "2022-09-29T14:39:53.364407Z",
          updated_at: "2022-09-29T14:39:53.364407Z"
        },
        {
          id: "3",
          count: 0,
          record_time: "2022-10-03T14:39:53.364407Z",
          created_at: "2022-09-29T14:39:53.364407Z",
          updated_at: "2022-09-29T14:39:53.364407Z"
        },
        {
          id: "4",
          count: 0,
          record_time: "2022-10-04T14:39:53.364407Z",
          created_at: "2022-09-29T14:39:53.364407Z",
          updated_at: "2022-09-29T14:39:53.364407Z"
        },
        {
          id: "5",
          count: 0,
          record_time: "2022-10-05T14:39:53.364407Z",
          created_at: "2022-09-29T14:39:53.364407Z",
          updated_at: "2022-09-29T14:39:53.364407Z"
        }
      ])
    })
  }

  getTotalUserLast(organizationId: string, projectId: string): Promise<TotalUser> {
    return Promise.resolve({
      id: "hsdkjfhs",
      count: 140,
      record_time: "2022-11-01T14:39:53.364407Z",
      created_at: "2022-09-29T14:39:53.364407Z",
      updated_at: "2022-09-29T14:39:53.364407Z"
    })
  }

  uploadUserImportFile(organizationId: string, projectId: string, formData: FormData): Promise<any> {
    return Promise.resolve()
  }

  listUserImportJobs(organizationId: string, projectId: string): Promise<UserImportJob[]> {
    return Promise.resolve([
      {
        id: "123",
        status: "pending",
        created_at: "2022-09-28T14:39:53.364407Z"
      },
      {
        id: "abc",
        status: "failure",
        created_at: "2022-09-29T14:39:53.364407Z"
      },
      {
        id: "qwert",
        status: "success",
        created_at: "2022-09-30T14:39:53.364407Z"
      }
    ])
  }

  getUserImportJobLogs(organizationId: string, projectId: string, jobId: string): Promise<string> {
    return Promise.resolve("2023/07/13 09:31:25 Using config file: /etc/config/config.yaml\n" +
      "2023/07/13 09:31:35 Failed to create user with id: d0f8fe0f-9fd0-4974-a927-63aaa54be787 : failed to close prepared statement: ERROR: current transaction is aborted, commands ignored until end of transaction block (SQLSTATE 25P02): ERROR: duplicate key value violates unique constraint \"users_pkey\" (SQLSTATE 23505)\n")
  }

  createApiKey(organizationId: string, projectId: string): Promise<CreateApiKeyResponse> {
    return Promise.resolve({
      id: "randomId",
      secret: "verySecureSecret"
    })
  }

  deleteApiKey(organizationId: string, projectId: string, apiKeyId: string): Promise<any> {
    return Promise.resolve()
  }

  getWebhooks(request: Request, organizationId: string, projectId: string): Promise<Webhook[]> {
    return Promise.resolve([
      {
        callback: "https://example.com/webhook",
        events: ["user"]
      }, {
        callback: "https://test.com/hanko/webhook/this/is/a/very/very/long/url/to/test/stuff/and/this/must/be/longer",
        events: [
          "user",
          "user.create",
          "user.update",
          "user.delete",
          "user.update.email",
          "user.update.email.create",
          "user.update.email.primary",
          "user.update.email.delete",
        ]
      },
      {
        callback: "https://cloud.stg.hanko.io/api/stripe/webhook",
        events: [
          "user",
          "user.create",
          "user.update",
          "user.delete",
          "user.update.email",
          "user.update.email.create",
          "user.update.email.primary",
          "user.update.email.delete",
        ]
      }
    ])
  }

  createWebhook(organizationId: string, projectId: string, data: CreateWebhook): Promise<Webhook[]> {
    return Promise.resolve([
      {
        callback: "https://example.com/webhook",
        events: ["user"]
      }, {
        callback: "https://test.com/hanko/webhook",
        events: ["user.update.email.create", "user.update.email.delete"]
      }
    ])
  }

  updateWebhook(organizationId: string, projectId: string, data: UpdateWebhook): Promise<Webhook[]> {
    return Promise.resolve([
      {
        callback: "https://example.com/webhook",
        events: ["user"]
      }, {
        callback: "https://test.com/hanko/webhook",
        events: ["user.update.email.create", "user.update.email.delete"]
      }
    ])
  }

  deleteWebhook(organizationId: string, projectId: string, data: DeleteWebhook): Promise<Webhook[]> {
    return Promise.resolve([
      {
        callback: "https://example.com/webhook",
        events: ["user"]
      }, {
        callback: "https://test.com/hanko/webhook",
        events: ["user.update.email.create", "user.update.email.delete"]
      }
    ])
  }

  createSamlIdentityProvider(organizationId: string, projectId: string, data: CreateUpdateSamlIdentityProvider): Promise<SamlIdentityProvider> {
    return Promise.resolve({
      id: "sdfsdf",
      ...data
    })
  }

  updateSamlIdentityProvider(organizationId: string, projectId: string, providerId: string, data: CreateUpdateSamlIdentityProvider): Promise<CreateUpdateSamlIdentityProvider> {
    return Promise.resolve(data)
  }

  deleteSamlIdentityProvider(organizationId: string, projectId: string, providerId: string): Promise<any> {
    return Promise.resolve()
  }

  createProjectUserEmailAddress(organizationId: string, projectId: string, userId: string, data: CreateEmailAddress): Promise<Email> {
    return Promise.resolve({
      id: "asdf",
      created_at: "2022-09-29T14:39:53.364407Z",
      updated_at: "2022-09-29T14:39:53.364407Z",
      is_primary: false,
      ...data
    })
  }

  deleteProjectUserEmailAddress(organizationId: string, projectId: string, userId: string, emailId: string): Promise<any> {
    return Promise.resolve()
  }

  setProjectUserEmailAsPrimary(organizationId: string, projectId: string, userId: string, emailId: string): Promise<any> {
    return Promise.resolve()
  }

  createCustomDomain(organizationId: string, projectId: string, data: CreateCustomDomain): Promise<CreateCustomDomain> {
    return Promise.resolve(data)
  }

  deleteCustomDomain(organizationId: string, projectId: string): Promise<any> {
    return Promise.resolve()
  }

  getCustomDomainStatus(organizationId: string, projectId: string): Promise<CustomDomain> {
    return Promise.resolve({
      domain: "login.moackapiclient.com",
      is_active: false,
      expected_cname: "cname.localhost",
      is_suspended: true
    })
  }

  reactivateCustomDomainVerification(organizationId: string, projectId: string): Promise<any> {
    return Promise.resolve()
  }

  getChatwootUserInfo(): Promise<ChatwootUserInfo> {
    return Promise.resolve({
      user_id: "sdfjsdlk",
      email: "example@example.com",
      identifier_hash: "kjdsklfjd"
    })
  }

  deleteProjectUserWebAuthnCredential(organizationId: string, projectId: string, userId: string, credentialId: string): Promise<any> {
    return Promise.resolve()
  }

  getProjectUserSessions(request: Request, organizationId: string, projectId: string, userId: string): Promise<Session[]> {
    return Promise.resolve([
      {
        id: "sdfs",
        user_agent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_12_6) AppleWebKit/603.3.8 (KHTML, like Gecko) Version/10.1.2 Safari/603.3.8",
        ip_address: "127.0.0.1",
        browser: "Safari",
        os: "MacOS",
        last_used: "2023-11-07T05:31:56Z",
        created_at: "2023-11-07T05:31:56Z",
        updated_at: "2023-11-07T05:31:56Z",
        expires_at: "2023-11-07T05:31:56Z"
      },
      {
        id: "sdfsadsdasdasd",
        user_agent: "Mozilla/5.0 (Linux; Android 4.3; GT-I9300 Build/JSS15J) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/59.0.3071.125 Mobile Safari/537.36",
        ip_address: "10.0.0.1",
        browser: "Chrome",
        os: "Android",
        last_used: "2024-11-07T05:31:56Z",
        created_at: "2024-11-07T05:31:56Z",
        updated_at: "2024-11-07T05:31:56Z",
        expires_at: "2024-11-07T05:31:56Z"
      },
      {
        id: "sdfsadsdasdasdadsadasd",
        last_used: "2024-11-07T05:31:56Z",
        created_at: "2024-11-07T05:31:56Z",
        updated_at: "2024-11-07T05:31:56Z",
        expires_at: "2024-11-07T05:31:56Z"
      }
    ])
  }

  deleteProjectUserSession(organizationId: string, projectId: string, userId: string, sessionId: string): Promise<any> {
    return Promise.resolve()
  }

  deleteProjectUserOtpSecret(organizationId: string, projectId: string, userId: string): Promise<any> {
    return Promise.resolve()
  }

  createProjectUserPassword(organizationId: string, projectId: string, userId: string, data: CreateOrUpdatePassword): Promise<any> {
    return Promise.resolve()
  }

  updateProjectUserPassword(organizationId: string, projectId: string, userId: string, data: CreateOrUpdatePassword): Promise<any> {
    return Promise.resolve()
  }

  deleteProjectUserPassword(organizationId: string, projectId: string, userId: string): Promise<any> {
    return Promise.resolve()
  }
}

const organization: Organization = {
  id: "d0ed8071-7773-40d2-bb9d-f21fde14f8fe",
  name: "Mock Organization",
  currency: undefined,
  created_at: "2022-09-29T14:39:53.364407Z",
  updated_at: "2022-09-29T14:39:53.364407Z",
  limit: {
    max_projects: 2,
    max_monthly_active_users: 10000,
    audit_log_retention_time: 7,
    enterprise_connections: 1
  }
}

const organization_2: Organization = {
  id: "1990ab0c-d589-4dd5-b321-27b3e26f6851",
  name: "Subscription Organization",
  currency: undefined,
  created_at: "2022-09-29T14:39:53.364407Z",
  updated_at: "2022-09-29T14:39:53.364407Z",
  limit: {
    max_projects: 5,
    max_monthly_active_users: 0,
    audit_log_retention_time: 7,
    enterprise_connections: 0
  }
}

const project: Project = {
  id: "caee2193-1701-4151-87c2-bbfbf8c87118",
  application_name: "MockAppWithAVeryLongNameSoThatWeCanSeeThatTheLayoutIsBroken",
  application_url: "https://mock.example.com",
  api_url: "https://caee2193-1701-4151-87c2-bbfbf8c87118.cloud.hanko.test",
  // smtp: {
  //   host: "smtp.amazonaws.com",
  //   port: "2525",
  //   user: "aws-smtp-user",
  //   password: "Sicher!2"
  // },
  additional_webauthn_origins: ["android:apk-key-hash:"],
  email_from_address: "hello@hanko.io",
  api_version: 'v0.8.0',
  status: {
    replicas: 1
  },
  allow_self_service_account_deletion: false,
  allow_signup: true,
  admin_api: {
    enabled: false,
    api_keys: ["caee2193-1701-4151-87c2-bbfbf8c87118", "01b3d7b1-23ed-4d1e-82cd-3d04a0c6be39"]
    // api_keys: []
  },
  username: {
    enabled: true,
    optional: true,
    acquire_on_registration: true,
    acquire_on_login: true,
    min_length: 3,
    max_length: 100,
    use_as_login_identifier: true,
  },
  email: {
    enabled: true,
    optional: true,
    acquire_on_registration: true,
    acquire_on_login: true,
    require_verification: true,
    use_as_login_identifier: true,
    use_for_authentication: true,
    limit: 5,
    max_length: 100,
    passcode_ttl: 300,
  },
  password: {
    enabled: true,
    optional: true,
    acquire_on_registration: "always",
    acquire_on_login: "always",
    min_length: 8,
    recovery: true,
  },
  passkey: {
    enabled: true,
    optional: true,
    acquire_on_registration: "always",
    acquire_on_login: "always",
    limit: 100,
    user_verification: "required",
    attestation_preference: "direct",
  },
  third_party: {
    error_redirect_url: "",
    allowed_redirect_urls: ["https://example.com**"],
    providers: {
      google: {
        enabled: true,
        client_id: "",
        secret: "",
        allow_linking: true
      },
      github: {
        enabled: false,
        client_id: "",
        secret: "",
        allow_linking: true
      },
      apple: {
        enabled: false,
        client_id: "",
        secret: "",
        allow_linking: true
      },
      discord: {
        enabled: false,
        client_id: "",
        secret: "",
        allow_linking: true
      },
      microsoft: {
        enabled: false,
        client_id: "",
        secret: "",
        allow_linking: true
      },
      linkedin: {
        enabled: false,
        client_id: "",
        secret: "",
        allow_linking: true
      },
      facebook: {
        enabled: false,
        client_id: "",
        secret: "",
        allow_linking: true
      }
    },
  },
  email_delivery_enabled: true,
  saml: {
    options: {},
    identity_providers: [
      {
        id: "someID",
        enabled: true,
        name: "Telekom SAML",
        domain: "telekom.de",
        metadata_url: "https://telekom.de/saml/metadata",
        skip_email_verification: false
      }
    ]
  },
  mfa: {
    enabled: true,
    optional: true,
    acquire_on_login: true,
    acquire_on_registration: true,
    totp: {
      enabled: true
    },
    security_keys: {
      enabled: true,
      authenticator_attachment: "platform",
      user_verification: "required",
      attestation_preference: "direct",
    },
    device_trust_policy: 'never',
    device_trust_duration: '720h'
  },
  custom_domain: {
    domain: "login.test.example.com",
    is_active: false,
    expected_cname: "cname.localhost",
    is_suspended: true
  }
}

const project2: Project = {
  id: "01b3d7b1-23ed-4d1e-82cd-3d04a0c6be39",
  api_url: "https://01b3d7b1-23ed-4d1e-82cd-3d04a0c6be39.cloud.hanko.test",
  application_name: "Mock App #2",
  application_url: "https://mocke-di-mock-mock.example.com",
  smtp: undefined,
  api_version: 'v0.8.0',
  status: {
    replicas: 0
  },
  allow_self_service_account_deletion: false,
  allow_signup: true,
  admin_api: {
    enabled: false,
    api_keys: []
  },
  username: {
    enabled: true,
    optional: true,
    acquire_on_registration: true,
    acquire_on_login: true,
    min_length: 3,
    max_length: 100,
    use_as_login_identifier: true,
  },
  email: {
    enabled: true,
    optional: true,
    acquire_on_registration: true,
    acquire_on_login: true,
    require_verification: true,
    use_as_login_identifier: true,
    use_for_authentication: true,
    limit: 5,
    max_length: 100,
    passcode_ttl: 300,
  },
  password: {
    enabled: true,
    optional: true,
    acquire_on_registration: "always",
    acquire_on_login: "always",
    min_length: 8,
    recovery: true,
  },
  passkey: {
    enabled: true,
    optional: true,
    acquire_on_registration: "always",
    acquire_on_login: "always",
    limit: 100,
    user_verification: "required",
    attestation_preference: "direct",
  },
  third_party: {
    error_redirect_url: "",
    allowed_redirect_urls: [],
    providers: {
      google: {
        enabled: true,
        client_id: "",
        secret: "",
        allow_linking: false
      },
      github: {
        enabled: false,
        client_id: "",
        secret: "",
        allow_linking: false
      },
      apple: {
        enabled: false,
        client_id: "",
        secret: "",
        allow_linking: false
      },
      discord: {
        enabled: false,
        client_id: "",
        secret: "",
        allow_linking: false
      },
      microsoft: {
        enabled: false,
        client_id: "",
        secret: "",
        allow_linking: false
      },
      linkedin: {
        enabled: false,
        client_id: "",
        secret: "",
        allow_linking: false
      },
      facebook: {
        enabled: false,
        client_id: "",
        secret: "",
        allow_linking: true
      }
    }
  },
  email_delivery_enabled: true,
  saml: {
    options: {},
    identity_providers: []
  },
  mfa: {
    enabled: true,
    optional: true,
    acquire_on_login: true,
    acquire_on_registration: true,
    totp: {
      enabled: true
    },
    security_keys: {
      enabled: true,
      authenticator_attachment: "platform",
      user_verification: "required",
      attestation_preference: "direct",
    },
    device_trust_policy: 'never',
    device_trust_duration: '720h'
  }
}

const auditLogTypes = [
  "user_created",
  "password_set_succeeded",
  "password_set_failed",
  "password_login_succeeded",
  "password_login_failed",
  "passcode_login_init_succeeded",
  "passcode_login_init_failed",
  "passcode_login_final_succeeded",
  "passcode_login_final_failed",
  "webauthn_registration_init_succeeded",
  "webauthn_registration_init_failed",
  "webauthn_registration_final_succeeded",
  "webauthn_registration_final_failed",
  "webauthn_authentication_init_succeeded",
  "webauthn_authentication_init_failed",
  "webauthn_authentication_final_succeeded",
  "webauthn_authentication_final_failed",
]

const projectStatus: ProjectStatus = {
  available_replicas: 1,
  ready_replicas: 1,
  replicas: 0,
}
