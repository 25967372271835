import React, { useEffect, useState } from 'react'
import { AppBar, Box, Button, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactComponent as Logo } from "../../assets/hanko-logo.svg";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { AccountCircle } from "@mui/icons-material";
import { Profile } from "../Profile";
import { Email, Hanko } from "@teamhanko/hanko-frontend-sdk";
import { ProjectType } from "../../const/types";
import { ProjectTypeLabel } from "../ProjectTypeLabel";

interface Props {
  onMenuClick: () => void,
  navBarWidth: number,
  projectName?: string
  projectType?: ProjectType
}

const UserBar = (props: Props) => {
  const hanko = new Hanko(window.REACT_APP_LOGIN_PROVIDER_URL)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [profileModalOpen, setProfileModalOpen] = useState(false)
  const theme = useTheme()
  const [email, setEmail] = useState<Email | undefined>(undefined)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    // @ts-ignore
    window.$chatwoot.reset();

    await hanko.user.logout();
    window.location.reload();
  }

  const closeProfileModal = () => {
    setProfileModalOpen(false)
  }

  const openProfileModal = () => {
    setProfileModalOpen(true)
    handleClose()
  }

  useEffect(() => {
    hanko.email.list().then(result => {
      setEmail(result.find(e => e.is_primary))
    })
  }, [])

  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{
          width: {sm: `calc(100% - ${props.navBarWidth}px)`},
          ml: {sm: `${props.navBarWidth}px`},
          bgcolor: theme.palette.background.default,
          backgroundImage: "none",
          boxShadow: 'unset',
        }}
      >
        <Toolbar sx={{display: "flex", px: {xs: 3, sm: 6, xl: 12}, minHeight: '84px'}} disableGutters variant="large">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={props.onMenuClick}
            sx={{mr: 2, display: {sm: 'none'}}}
          >
            <MenuIcon/>
          </IconButton>
          <Box sx={{mr: 2, display: {xs: 'flex', sm: 'none'}, alignItems: 'center'}}>
            <Logo
              title="Hanko Logo"
              height="50px"
            />
          </Box>
          {props.projectType !== undefined ? <ProjectTypeLabel projectType={props.projectType} sx={{mr: 2, display: {xs: 'none', md: 'block'}}} /> : null}
          <Typography variant="body1" sx={{
            color: '#7C8E9C',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: {xs: 'none', sm: 'block'},
          }}>{props.projectName}</Typography>
          <Box sx={{
            display: 'flex',
            justifyContent: 'end',
            marginLeft: "auto",
            width: {xs: 'calc(100vw - 257px)', sm: 'fit-content'},
            maxWidth: {sm: '200px', md: '300px', lg: '400px'}
          }}>
            <Button variant="text" endIcon={<KeyboardArrowDownIcon/>} onClick={handleMenu} sx={{color: 'white'}}>
              <Typography variant="body1" style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}>{email?.address}</Typography>
              <AccountCircle sx={{ml: 1}}/>
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={openProfileModal}>Profile</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Profile open={profileModalOpen} onClose={closeProfileModal}></Profile>
    </Box>
  )
}

export default UserBar