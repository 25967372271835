import React, { useContext, useState } from "react";
import { SnackbarContext } from "../../../../components/Snackbar/SnackbarProvider";
import { useRevalidator } from "react-router-dom";
import { ApiClient } from "../../../../client/ApiClient";
import { HankoDialog } from "../../../../components/HankoDialog";
import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

interface Props {
  type: "create" | "update"
  organizationId: string
  projectId: string
  userId: string
  open: boolean
  onClose: () => void
}

export const PasswordModal = (props: Props) => {
  const { type, organizationId, projectId, userId, open, onClose } = props
  const { error, success } = useContext(SnackbarContext)
  const [isLoading, setIsLoading] = useState(false)
  const revalidator = useRevalidator()

  const onSubmit = async (e: React.FormEvent) => {
    setIsLoading(true)
    e.preventDefault()
    const target = e.target as typeof e.target & {
      password: { value: string }
    }

    try {
      if (type === "create") {
        await ApiClient.createProjectUserPassword(organizationId, projectId, userId, {
          password: target.password.value,
        })
        success("Password created")
      } else {
        await ApiClient.updateProjectUserPassword(organizationId, projectId, userId, {
          password: target.password.value,
        })
        success("Password updated")
      }
      revalidator.revalidate()
      onClose()
    } catch (err) {
      if (type === "create") {
        error("Failed to create password")
      } else {
        error("Failed to update password")
      }
    }

    setIsLoading(false)
  }

  return <HankoDialog onClose={onClose} open={open}>
    <Box sx={{ display: "flex", flexDirection: 'column', width: '320px', maxWidth: '320px', margin: 'auto' }}>
      <Typography variant="h4">{type === "create" ? "Create password" : "Update password"}</Typography>
      <Typography sx={{
        mt: 2.5,
        mb: 2.5
      }}>{type === "create" ? "Create a password for the user." : "Update the password for the user."}</Typography>
      <form onSubmit={onSubmit} autoComplete="off">
        <FormControl fullWidth focused>
          <TextField type="password" required name="password" placeholder="password"
                     autoFocus label="Password" autoComplete="off" />
          <LoadingButton loading={isLoading} variant="contained" type="submit" fullWidth sx={{
            mt: 2,
            mb: 2
          }}>{type === "create" ? "Create password" : "Update password"}</LoadingButton>
          <Button variant="text" fullWidth onClick={onClose}>Cancel</Button>
        </FormControl>
      </form>
    </Box>
  </HankoDialog>
}